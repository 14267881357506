import React, {useEffect, useRef, useState} from 'react';
import background from './background.png';
import './App.css';
import song from './song.mp3';
import useSound from 'use-sound';

import { ref, set, get } from "firebase/database";

const fetch = require('fetch-retry')(global.fetch);
const sanitizedIp = (geo) => geo?.IPv4?.replaceAll('.','-') ?? 'unknown';

function App({ db }) {
    const [rsvp, setRsvp] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [recentSubmit, setRecentSubmit] = useState(false);
    const [geo, setGeo] = useState({});

    const [play, { pause }] = useSound(song, {
        volume: 0.05,
    });

    useEffect(() => {
        if (menuOpen || rsvp) play();
        else pause();

        return () => pause();
    }, [menuOpen, rsvp]);

    const messageRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        fetch('https://geolocation-db.com/json/', { retries: 10 })
            .then((res) => res.json())
            .then((json) => {
                setGeo(json);
                return json;
            })
            .then((geo) => {
                return geo;
            })
            .then((geo) => {
                const now = new Date();
                set(ref(db, `responses/${sanitizedIp(geo)}/geo`), {
                    ...geo,
                    timestamp: now.toString()
                });
                return get(ref(db, `responses/${sanitizedIp(geo)}/latest`));
            })
            .then((value) => {
                if (value.exists()) setRsvp(value.val().response === 'yes');
            });
    }, []);

    const sendResponse = (response) => {
        const path = `responses/${sanitizedIp(geo)}/${Date.now().toString().split(' ').join('')}`;
        const latestPath = `responses/${sanitizedIp(geo)}/latest`;

        const data = { response, message: messageRef.current?.value ?? '', geo };

        Promise.all([set(ref(db, path), data), set(ref(db, latestPath), data)]).then(() => {
            if (messageRef.current) messageRef.current.value = '';
            if (response !== 'no') {
                setRecentSubmit(true);
                setTimeout(() => setRecentSubmit(false), 2000);
            }
        });
    };

    const yesHandler = () => {
        setMenuOpen(false);
        setRsvp(true);

        sendResponse('yes');
    };

    const noHandler = () => {
        setMenuOpen(false);
        setRsvp(false);

        sendResponse('no');

        window.alert("Sorry, we're experiencing some technical difficulties. \"No\" is not available as an option at" +
            " this moment.\n\nPlease reach out to (501) 773-9735 to provide feedback.");
    };


    return (
        <>
            <div className="App">
                <img src={background} />
                <div className="content">
                    <div id="text">
                        <div id="intro">Celebrating the reunion of</div>
                        <div id="names">Sam & Afshan</div>
                        <div id="date">11 March 2023</div>
                    </div>
                    {/*@ts-ignore*/}
                    <button id="rsvpButton" onClick={() => setMenuOpen((val) => !val)}>
                        <div id="carousel">
                            <span style={recentSubmit ? { opacity: '1', transform: 'translateY(50%)' } : { opacity: '0', transform: 'translateY(-100%)' }}>Submitted!</span>
                            <span style={recentSubmit ? { opacity: '0', transform: 'translateY(100%)' } : { opacity: '1' }}>{(menuOpen || !rsvp) ? "RSVP" : 'Yes ♡'}</span>
                        </div>
                    </button>

                    <div id="options" style={menuOpen ? { opacity: '1' } : { opacity: '0', transform: 'translateX(-50%) translateY(3px)' }}>
                        <textarea ref={messageRef} placeholder="Add an optional message (e.g. pickup location)" />
                        <div id="buttons">
                            <button id="yes" onClick={yesHandler}>Yes ♡</button>
                            <button id="maybe" onClick={yesHandler}><span>Maybe</span></button>
                            <button id="no" onClick={noHandler}>No</button>
                        </div>
                    </div>
                </div>
            </div>
            <a id="playlist" target="_blank" href="https://open.spotify.com/playlist/0fL0f01WsNpCch0rmQzfe7?si=233a513d86034c1a">
                Listen to the reunion playlist ►
            </a>
        </>
    );
}

export default App;
